<template>
  <div class="pt-3">
    <Loader v-if="performingRequest" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div class="whiteBack">
          <h4>Add New Panel</h4>
          <div class="mt-2">
            <label for="title">Choose State:</label>
            <v-select
            class="mt-1"
              label="title" 
              :options="states"
              v-model="state"
              :clearable="true"
              >
            </v-select>
          </div>

          <div class="mt-4" >
            <label for="fileTitle" v-if="state">Upload File:</label>
            <div class="mt-2" v-if="state">
            <input type="file" ref="fileInputState" id="panelUpload" accept="image/*,application/pdf,.doc" @change="previewImage" hidden>
            <button @click="chooseFile()" class="btn btn__primary mb-2">Upload File</button>
            </div>

            <!-- <input type="file" ref="fileInputTip" accept="image/*,application/pdf,.doc" @change="previewImage"> -->
            <progress :value="uploadValue" max="100" v-if="showBar"></progress>
           <!--  <div class="mb-3">
              <button v-if="imageData != null && type" class="btn btn__primary mt-3" @click="onUploadFile">
                Upload
                <transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col" style="width:100%; background:white;">
        <div>
          <h4 class="mb-3">Panels</h4>
          <div v-if="panels && panels.length >= 1" class="pt-3">
            <vue-good-table
              :columns="columns"
              :rows="panels"
              >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'url'">
                  <a :href="props.row.url" target="_blank">View <i class="fas fa-external-link"></i></a>
                </span>
                <span v-else-if="props.column.field == 'created'">
                  <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
                </span>
                <span v-else-if="props.column.field == 'state'">
                  {{props.row.state}}
                </span>
                <span v-else-if="props.column.field == 'extras'">

                    <i class="fas fa-trash" @click="deleteUploadedFile(props.row, props.index)"></i>
    
                </span>
                <!-- <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span> -->
              </template>
            </vue-good-table>
          </div>
        
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')
import * as moment from 'moment'

export default {
  name: 'managePanels',
  data: () => ({
    imageData: null,
    state: '',
    states: ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
    uploadValue: 0,
    showBar:false,
    performingRequest: false,
    columns: [
      {
        label: 'Uploaded',
        field: 'created',
        sortable: false,
        width: '100px'
      },
      {
        label: 'State',
        field: 'state',
        width: '180px'
      },
      {
        label: 'Url',
        field: 'url',
        sortable: false,
        width: '60px'
      },
      {
        label: 'Delete',
        field: 'extras',
        sortable: false,
        width: '60px'
      },
    ]
  }),
  created () {
    this.$store.dispatch("getPanels")   
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'panels']),
  },
  components: {
    Loader,
  },
  methods: {
    chooseFile() {
      document.getElementById("panelUpload").click()
    },
    previewImage(event) {
      console.log(event)
      this.uploadValue=0;
      this.imageData=event.target.files[0]
      this.onUploadFile()
    },
    onUploadFile() {
      console.log('onUpload')
      this.showBar = true
      let state = this.state
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let storageRef = fb.storageRef.child('docs/' + rand).put(this.imageData);
      storageRef.on(`state_changed`, snapshot => {
        this.uploadValue=(snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error => {console.log(error.message)},
      () => {this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          fb.panelsCollection.add({
            state: state,
            url: downloadURL
          });
        })
        this.showBar = false
      })
      this.imageData = null
      this.state = ''
      this.$refs.fileInputState.value=null
    },
    deleteUploadedFile(u, index) {
      fb.panelsCollection.doc(u.id).delete()
    },
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('MM/DD/YYYY')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("clearPanels")
  }
}
</script>